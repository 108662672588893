.port-pic {
    float: right;
    width: 90vw;
    height: 35vh;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid black;
    margin: 1vh 2vw;
    max-width: 1200px;
}


.about-header {
    text-align: center;
}

.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 500px;
    padding-top: 6rem;
    padding-bottom: 3rem;
    padding-right: 3rem;
    padding-left: 3rem;
}

@media (min-width: 700px) {
    .port-pic {
        height: 45vh
    }
}

@media (min-width: 1000px) {
    .port-pic {
        height: 55vh
    }
}

