.footer-social-media-container{
    background-color: hsl(var(--clr-black));
    color:white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding-right: 6rem;
    padding-left: 6rem;
    min-height: 5rem;
    align-items: center;
}


.socials-image{
    height: 50px;
    width: auto;
}

@media(max-width: 500px ){
    .footer-social-media-container{
        padding: 5px;

    }
}