.hero-container{
    padding: -15px 2vw;
    clip-path: ellipse(233% 100% at -32.51% 0%);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
}
.hero-img{
    float:left;
    width: 80%;
    height: 55vh;
    object-fit: cover;
    border-radius: 20px;
    border: 1px solid black;
}
.hero-text-container{
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;


}
.hero-header{
    font-size: clamp(var(--fs-400), 7.5vw, var(--fs-900));
}

@media  (max-width: 1000px){
    .hero-container{
        min-height: 50vh;
    }
}

@media(max-width: 600px){
    .hero-container{
        min-height: 40vh;
    }
}
