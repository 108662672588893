.nav-container{
    height: 80px;
    box-shadow: 0 2px 10px hsl(var(--clr-black), .2);
    justify-content: space-between;
    align-items: center;
    padding-right: 2vw;
}
.nav-logo{
    padding-top: .8rem;
    padding-left: .8rem;
    height: 80px;
    width: auto;
}
.nav-item{
    padding: .5rem .5vw;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
}
.nav-item>a{
    color: inherit;
    font-family: var(--ff-cursive);
}

@media(max-width: 500px){
    .nav-logo{
        padding: 5px;
    }
    .nav-item{
        font-weight: 400;
        font-size: var(--fs-200);
    }
}

